import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointUp, faMousePointer, faPaintBrush, faEraser, faSave } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@material-ui/core';
import json2mq from 'json2mq';
import { shortQueries } from '../queries';
import { Strings } from '../App';

const buttonStyle = {
    padding: 10,
    position: 'relative',
    float: 'right',
    background: '#2BE396',
    borderRadius: 10,
    minWidth: 70,
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 'bold'
}



export const GoodBadEstimationsTour = (props) => {

    const short = useMediaQuery(json2mq(shortQueries));

    const strings = useContext(Strings)

    const { tourPages, pageLogo } = strings || {}
    const { estimateExample } = tourPages || {}
    const {
        instructionText,
        image,
        buttonText
    } = estimateExample

    const textStyle = {
        fontFamily: "'Open Sans', sans-serif",
        fontSize: short ? 14 : 16
    }

    return (
        <div style={textStyle}>
            <img src={pageLogo ? pageLogo.url : "/Measureit-logo.png"} alt="measureit-welcome" style={{height: pageLogo ? pageLogo.height : 40, width: pageLogo? pageLogo.width : 40}} />
            <br />
            {instructionText ? <div dangerouslySetInnerHTML={{__html: instructionText}}></div> : null}
            {false ? null : (
                <img src={image ? image.url : "/mobilerightwrong2.png"} alt="good bad examples" style={{width: image ? image.width : '100%', height: image ? image.height : 100}} />
            )}
            <button className='action-button' onClick={() => props.closeTour(false)} style={buttonStyle}>{buttonText}</button>
        </div>
    )
}

GoodBadEstimationsTour.propTypes = {
    closeTour: PropTypes.func.isRequired
}