import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointUp, faMousePointer, faPaintBrush, faEraser, faSave, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@material-ui/core';
import json2mq from 'json2mq';
import { shortQueries } from '../queries';
import { Strings } from '../App';

const buttonStyle = {
    padding: 10,
    position: 'relative',
    float: 'right',
    background: '#2BE396',
    borderRadius: 10,
    minWidth: 70,
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 'bold'
}



export const MeasurItColorEraseTour = (props) => {

    const short = useMediaQuery(json2mq(shortQueries));

    const strings = useContext(Strings)

    const { tourPages, pageLogo } = strings || {}
    const { estimateInstructions } = tourPages || {}
    const {
        instructionText,
        image,
        buttonText
    } = estimateInstructions

    const textStyle = {
        fontFamily: "'Open Sans', sans-serif",
        fontSize: short ? 14 : 16
    }

    return (
        <div style={textStyle}>
            
            <img src={pageLogo.url || "/Measureit-logo.png"} alt="measureit-welcome" style={{height: pageLogo.height || 40, width: pageLogo.width || 40}} />
            <br />
            {!instructionText ? <p>
            Use your finger {<FontAwesomeIcon icon={faHandPointUp} />} or cursor {<FontAwesomeIcon icon={faMousePointer} />} to <span className='highlight' style={{textDecoration: 'underline', fontWeight: 'bolder', color: 'rgb(43, 227, 150)'}}>color in the area</span> which you want serviced.
            Use the brush {<FontAwesomeIcon icon={faPaintBrush} />} and undo {<FontAwesomeIcon icon={faUndoAlt} />} tools fine tune your selection.
            <br/><br/>
            Save the estimate by clicking the save {<FontAwesomeIcon icon={faSave} />} button.
            </p> : instructionText}
            {false ? null : (
                <img src={image ? image.url :"/measur.it-draw-undo.gif"} alt="animate pan zoom" style={{width: image? image.width: 200, height: image ? image.height: 200}} />
            )}
            <button className='action-button' onClick={() => props.goTo(1)} style={buttonStyle}>{buttonText}</button>
        </div>
    )
}

MeasurItColorEraseTour.propTypes = {
    closeTour: PropTypes.func.isRequired
}