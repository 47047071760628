import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const styles ={
    display: 'flex',
    // width: '90%',
    height: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 15,
    fontFamily: "'Open Sans', sans-serif"
}

export const MeasurItTitleBar = (props) => {

    const [measurement, setMeasurement] = useState(props.measurement || 0.0);
    const [mode, setMode] = useState(props.mode || "area");

    let display = `${Math.round(measurement)} ${props.clientOptions.isMetric ? (mode === "area" ? 'sq m': 'linear m')
                                                     : (mode === "area" ? 'sq ft': 'linear ft') }`
    useEffect(() => {
        setMeasurement(props.measurement);
        setMode(props.mode);

        display = `${props.measurement.toFixed(2)} ${props.mode === "area" ? 'sq ft': 'linear ft'}`

    }, [props.measurement, props.mode]);

    return (<div
        style={styles}
    >
       <div style={{fontSize: 19, fontWeight: 'bold', marginLeft: 15}}>
           {display}
       </div>
       <img src="/Measureit-logo.png" alt={'measuring'} style={{height: 30, marginRight: '3vw'}} />
    </div>)
}

MeasurItTitleBar.propTypes = {
    mode: PropTypes.string.isRequired,
    measurement: PropTypes.number.isRequired
}