import React, { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faPaintBrush, 
  faEraser, 
  faUndoAlt,
  faTrashAlt,
  faCircle, 
  faUnlock,
  faTimes,
  faSave,
  faQuestion,
  faCheck,
} from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from '@material-ui/core';
import json2mq from 'json2mq';
import { shortQueries } from '../../queries';
import "./saveButton.css";

/*
  This component renders custom control buttons over the
  estimator's HTML canvas for area calculation.

  This component takes the following props:

  * color
  * brushSize
  * brushRadius
  * eraseMode
  * setEraseMode
  * setBrushRadius
  * handleUndoButton
  * handleClearButton
  * handleUnlockMapButton
  * handleSaveButton
*/

export default function AreaDrawControls(props) {

  const short = useMediaQuery(json2mq(shortQueries));

  const [showBrushSizes, setShowBrushSizes] = useState(false);
  const [showEraserSizes, setShowEraserSizes] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showBrushButton, setShowBrushButton] = useState(true);

  const toggleBrushSizes = () => {
    props.setEraseMode(false);
    setShowBrushSizes(prevState => !prevState);
    setShowEraserSizes(false);
  }

  const toggleEraserSizes = () => {
    props.setEraseMode(true);
    setShowEraserSizes(prevState => !prevState);
    setShowBrushSizes(false);
  }

  const setDefaultBrushSize = () => {
    if(props.clientOptions.brushSize === "large" ) {
      props.setBrushRadius(props.brushSize.large);
    } else if (props.clientOptions.brushSize === "medium") {
      props.setBrushRadius(props.brushSize.medium);
    } else if (props.clientOptions.brushSize === "small") {
      props.setBrushRadius(props.brushSize.small);
    } else if (props.clientOptions.brushSize === "tiny") {
      props.setBrushRadius(props.brushSize.tiny);
    }
  }

  const enableBrushButton = () => {
    if (props.clientOptions.disableBrush === true) {
      setShowBrushButton(false);
    } else {
      setShowBrushButton(true);
      
    }
  }
  
  const enableSaveButton = () => {
    if (props.clientOptions.disableSave === false) {
      setShowSaveButton(true);
    } else {
      (props.currentMeasurement > props.clientOptions.min && props.clientOptions.max > props.currentMeasurement) ? setShowSaveButton(true) : setShowSaveButton(false);
    }
  }

  useEffect( () => { 
    enableSaveButton();
    enableBrushButton();
    setDefaultBrushSize();
    
  }, [props.currentMeasurement, props.clientOptions] )

  const buttonStyle = {
    width: short ? "40px" : "50px",
    height: short ? "40px" : "50px",
    backgroundColor: props.color.button,
    border: "none",
    borderRadius: short ? "20px" : "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: short ? "20px" : "25px",
    color: props.color.iconActive,
    outline: 'none',
    cursor: 'pointer'
  };

  const helpButtonStyle = {...buttonStyle, 
    ...{width: short ? 20 : 30, height: short ? 20: 30, borderRadius: short ? 15 : 15, fontSize: short ? 15 : 20}
  }

  // Build brush size selection
  const brushSizeSelection = <div 
    id="brushSizeSelection"
    style={{
      display: "flex",
      flexDirection: "column-reverse"
    }}
  >
    <button 
      aria-label="Small brush"
      type="button" 
      onClick={()=> { props.setBrushRadius(props.brushSize.small); 
                    toggleBrushSizes();
                    }}
      style={{
        ...buttonStyle,
        color: (props.brushRadius === props.brushSize.small ? props.color.iconActive : props.color.iconInactive),
        fontSize: props.brushSize.small
      }}
    >
      <FontAwesomeIcon icon={faCircle} />
    </button>
    <button 
      aria-label="Medium brush"
      type="button" 
      onClick={()=> { props.setBrushRadius(props.brushSize.medium); 
                    toggleBrushSizes();
                    }}
      style={{
        ...buttonStyle,
        color: (props.brushRadius === props.brushSize.medium ? props.color.iconActive : props.color.iconInactive),
        fontSize: props.brushSize.medium
      }}
    >
      <FontAwesomeIcon icon={faCircle} />
    </button>
    <button 
      aria-label="Large brush"
      type="button" 
      onClick={()=> { props.setBrushRadius(props.brushSize.large); 
                    toggleBrushSizes();
                    }}
      style={{
        ...buttonStyle,
        color: (props.brushRadius === props.brushSize.large ? props.color.iconActive : props.color.iconInactive),
        fontSize: props.brushSize.large
      }}
    >
      <FontAwesomeIcon icon={faCircle} />
    </button>
  </div>

  // Build eraser size selection
  const eraserSizeSelection = <div 
    id="eraserSizeSelection"
    style={{
      display: "flex",
      flexDirection: "column-reverse"
    }}
  >
    <button 
      aria-label="Small eraser"
      type="button" 
      onClick={()=> {props.setBrushRadius(props.brushSize.small);
                    toggleEraserSizes();              
      } 
                  }
      style={{
        ...buttonStyle,
        color: (props.brushRadius === props.brushSize.small ? props.color.iconActive : props.color.iconInactive),
        fontSize: props.brushSize.small
      }}
    >
      <FontAwesomeIcon icon={faCircle} />
    </button>
    <button 
      aria-label="Medium eraser"
      type="button" 
      onClick={()=> {props.setBrushRadius(props.brushSize.medium);
                    toggleEraserSizes();              
      } 
                  }
      style={{
        ...buttonStyle,
        color: (props.brushRadius === props.brushSize.medium ? props.color.iconActive : props.color.iconInactive),
        fontSize: props.brushSize.medium
      }}
    >
      <FontAwesomeIcon icon={faCircle} />
    </button>
    <button 
      aria-label="Large eraser"
      type="button" 
      onClick={()=> {props.setBrushRadius(props.brushSize.large);
                    toggleEraserSizes();              
      } 
                  }
      style={{
        ...buttonStyle,
        color: (props.brushRadius === props.brushSize.large ? props.color.iconActive : props.color.iconInactive),
        fontSize: props.brushSize.large
      }}
    >
      <FontAwesomeIcon icon={faCircle} />
    </button>
  </div>

  const brushButton = (
    <div
    style={{
      backgroundColor: props.color.button,
      borderRadius: "20px",
      marginBottom: "10px",
      display: "flex",
      flexDirection: "column-reverse"
    }}
  >
    <button 
      aria-label="Select brush"
      type="button" 
      onClick={toggleBrushSizes}
      style={{
        ...buttonStyle,
        color: (props.eraseMode ? props.color.iconInactive : props.color.iconActive)
      }}
    >
      <FontAwesomeIcon icon={faPaintBrush} />
    </button>
  </div>
  );

  const saveButton = (
    <div 
      style={{

        position: "absolute",
        bottom:  '8%', //short ? "80px" : "35px",
        right: "20px",
        zIndex: 99

      }}
      className="draw-tools-save-button"
    >
    
      <button 
        aria-label="Save"
        type="button" 
        onClick={props.handleSaveButton}
        
        //style={buttonStyle}
        className="save-button"
      >
        {/*<FontAwesomeIcon icon={faCheck} /> */}
        Get My Quote ✅
      </button>
    </div> )

var url = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href;
url = url.replace(/^https?:\/\//,'https://');

  return (
    <Fragment>
      <link rel="stylesheet" type="text/css" href={`${url}measur.it/styles.css`} />
      {/* Top Controls */}
      <div
        style={{
          position: "absolute",
          top: short ? "10px" : "15px",
          left: "10px",
          zIndex: 99
        }}
      >
        <button 
          aria-label="Unlock map"
          type="button" 
          onClick={props.handleUnlockMapButton}
          //style={{...buttonStyle, ...{width: short ? 20 : 30, height: short ? 20: 30, borderRadius: short ? 15 : 15, fontSize: short ? 15 : 20}}}
          className="go-back-button"
        >
         {/* <FontAwesomeIcon icon={faTimes} /> */}
         Go Back
        </button>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: short ? "200px" : "160px",
          right: "20px",
          display: "flex",
          flexDirection: "column",
          zIndex: 99,
          // width: 300
        }}
        className="draw-tools-back-undo"
      >
        <button 
          aria-label="Undo"
          type="button" 
          onClick={props.handleUndoButton } 
          style={{
            ...buttonStyle,
            marginBottom: "10px"
          }}
        >
          <FontAwesomeIcon icon={faUndoAlt} />
        </button>
        <button 
          aria-label="Clear"
          type="button" 
          onClick={props.handleClearButton} 
          style={buttonStyle}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
      {/* Bottom Controls */}
      <div 
        style={{
          position: "absolute",
          bottom:  '8%', //short ? "100px" : "20px",
          left: "10px",
          display: "flex",
          flexDirection: "column",
          zIndex: 99,
          width: 100,
          height: 100
        }}
        className="draw-tools-brushes"
      >
        {/* Brush Controls */}
        
        { showBrushButton ? brushButton : null }
        {showBrushSizes ? brushSizeSelection : null}
        {/* Eraser Controls */}
        <div
          style={{
            backgroundColor: props.color.button,
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column-reverse"
          }}
          className="draw-tools-erasers"
        >
          {/* <button 
            aria-label="Select eraser"
            type="button" 
            onClick={toggleEraserSizes}
            style={{
              ...buttonStyle,
              color: (props.eraseMode ? props.color.iconActive : props.color.iconInactive)
            }}
            type="button" 
          >
            <FontAwesomeIcon icon={faEraser} />
          </button> */}
          { false ? 
            eraserSizeSelection
          :
            null
          }
        </div>
      </div>
      { /* Help Controls */}
        {/* <div 
        style={{
          position: "absolute",
          top:  '5%', //short ? "80px" : "35px",
          right: "20px",
          zIndex: 99
        }}
      >
        <button 
          aria-label="Help"
          type="button" 
          onClick={() => {}}
          style={helpButtonStyle}
        >
          <FontAwesomeIcon icon={faQuestion} />
        </button>
      </div> */}
      { /* Save Button */}
      { showSaveButton ? saveButton : null}

    </Fragment>
  );

}
