import React, { Fragment, useState, useEffect } from 'react';
import Tour from 'reactour';
import { useTheme } from '@material-ui/core/styles';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// Import data and settings
import {
  startingLocation,
  startingZoomLevel,
  GOOGLE_API_KEY,
  componentMode,
  parentURL
} from './imports/data.js';


// Import Estimator component
import Estimator from './estimator/estimator.js';
import { MeasurItTitleBar } from './interface/titlebar.js';
import { MeasurItWelcome } from './tour/welcome.tour.js';
import { MeasurItPanZoomTour } from './tour/pan-zoom.tour.js';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import json2mq from 'json2mq';
import { shortQueries } from './queries.js';
import useDebounce from './debounce.js';
import { MeasurItColorEraseTour } from './tour/color-erase.tour.js';
import { GoodBadEstimationsTour } from './tour/good-bad-estimations.tour.js';

/*
  This component serves as the controlling parent for
  the "dumb" estimator component.  Top-level functionality
  and state will be stored here and passed into the 
  subcomponents.
*/


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export const Strings = React.createContext()

export default function App() {

  const classes = useStyles();
  

  const allowedMethods = {
    setLatLng: ({lat,lng}) => {
      alert(`Lat: ${lat}, Lng: ${lng}`);
    },
    setAddressString: ({address}) => {
      setLoaded(false); 
      setAddress(address);
      const fetchUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_API_KEY}`
      fetch(fetchUrl).then(async (response) => {
        try {
         if (response.status === 200) {
          const body = await response.json();
          if (body.status === "OK") {
            if (body.results.length) {
              const first = body.results[0];
              setMapCenter(first.geometry.location);
              setTimeout(()=> {
                setLoaded(true);
              }, 500);
           
            } else {
              console.log("no results");
            }
          } else {
            console.log("status is not good");
          }
         } else {
          console.log("bad request");
         }
          
        } catch(e) {
          console.log("body parsing error", e)
        }
      }).catch(e => {
        console.log('geo error', e);
      });
    },
    alertMe: (str) => {
      console.log(str);
      alert(str);
    },
    options: (obj) => {
      setClientOptions({...clientOptions, ...obj});
    }
  }

  
  if (window.addEventListener) {
    window.addEventListener("message", onMessage, false);        
  } 
  else if (window.attachEvent) {
      window.attachEvent("onmessage", onMessage, false);
  }

  


  function onMessage(event) {
    // Check sender origin to be trusted
    // if (event.origin !== "http://example.com") return;

    var data = event.data;
    let func = allowedMethods[data.func];
    if (func) {
      if (typeof  func === "function") {
          func(data.args);
      }
    }
    
}


  // Build function to acquire window dimensions
  // IMPORTANT: the estimator is NOT responsive to changes
  // to the window width and height
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const preloadImages = (url) => {
    const img = new Image()
    img.src = url;
  }

  // preload images
  ["/measur.it-intro.png", "/Measureit-logo.png", 
  "/measurit-pan-zoom.gif", "/measur.it-draw-undo.gif" ].map(preloadImages)


  // Set initial state
  const [mode, setMode] = useState(componentMode);
  const [mapCenter, setMapCenter] = useState(startingLocation);
  const debouncePlaceLookup = useDebounce(mapCenter, 1000);
  const [mapBounds, setMapBounds] = useState(null);
  const [mapArea, setMapArea] = useState(0);
  const [coveragePercentage, setCoveragePercentage] = useState(0);
  const [coverageSquareArea, setCoverageSquareArea] = useState(0);
  const [drawnLinearDistance, setDrawnLinearDistance] = useState(0);
  const [address, setAddress] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [clientOptions, setClientOptions] = useState({disableSave: true, transparentDraw:false, min: 1, max: 40000, mode: "area", isMetric: false, brushSize: "medium", disableBrush: true, logo: "", companyName: "", lineOne: "", lineTwo: "", title: ""});

  const [saving, setSaving] = React.useState(false);

  const [strings, setStrings] = useState({})

  const theme = useTheme();

  var url = document.referrer
  var search = document.location.search;
  const urlParams = new URLSearchParams(search);

  let client = null
  let checkUrl = null
  let origin = null
  let host = null
  let protocol = null
  if (url) {
    url = new URL(url)
    host = url.host
    origin = url.origin
    protocol = url.protocol

    checkUrl = Buffer.from(host, 'utf-8').toString('base64')
    client = urlParams.get('client')
  }


  useEffect ( () => {

    determineMode();

  }, [clientOptions] )



  useEffect(() => {

    if (!loaded) {
      setTimeout( async () => {
        if (client && client === checkUrl) {
          let json = await getWhiteLabelJson({host, protocol})
          setStrings(json)
        }

        setLoaded(true);
      }, 1000);
    }
    if (loaded) {

      const placeNearbyUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${mapCenter.lat},${mapCenter.lng}&key=${GOOGLE_API_KEY}`;
    fetch(placeNearbyUrl, {
    }).then(async (response) => {
      try {
       if (true) {
        const body = await response.json();
        if (body.status === "OK") {
          let records = body.results || [];
          let roofs = records.filter(i => i.geometry.location_type === "ROOFTOP");
          if (roofs.length) {
            const first = roofs[0];
            const address = first.formatted_address;
            window.parent.postMessage({
              type: 'address_change',
              value: address
            }, "*");

          } else {
            console.log("no results");
          }
        } else {
          console.log("status is not good");
        }
       } else {
        console.log("bad request");
       }
        
      } catch(e) {
        console.log("body parsing error", e)
      }
    }).catch(e => {
      console.log('geo error', e);
    });

    }
    //setMapKey("mapKey12"+ Math.random() * 1000);
  },[debouncePlaceLookup])

  // Acquire window dimensions
  // IMPORTANT: the estimator is NOT responsive to changes
  // to the window width and height
  const windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight
  };

  const determineMode = () => {
    if (clientOptions.mode === "perimeter") {
      setMode(clientOptions.mode);
    } else if (clientOptions.mode === "area") {
      setMode(clientOptions.mode);  
    }
  }

  
  // SET OPTIONS

  // Map image parameters (number values)
  // These may be dictated by limitations within Google Maps Static API
  // Pay-As-You-Go max values are 640;
  // Google Maps Platform Premium Plan gets you 2048
  // https://developers.google.com/maps/documentation/maps-static/usage-and-billing
  const mapHeight = ((windowDimensions.height <= 640) ? windowDimensions.height :940);
  const mapWidth = ((windowDimensions.width <= 640) ? windowDimensions.width : 940);

  const containerHeight = (windowDimensions.height);
  // Specify colors for painting tool and buttons
  // Values can be any valid color name, hex value, or rgba
  // e.g. "green", "#00ff00", rgba(0,255,0,1.0)
  // Specify value of color for painting tool
  // NOTE: Use of transparent color is not recommended for paint
  // Required keys:  paint, iconActive, iconInactive, button
  const color = {
    paint:  "#2BE396",
    iconActive: "black",
    iconInactive: "lightgray",
    button: "whitesmoke"
  };

  if (clientOptions.transparentDraw === true) {
    color.paint = "rgba(43, 227, 150, 0.20)"
  }

  // Specify radius values of brushes
  // Radius value is number of pixels (e.g. 20)
  // Required keys: tiny, small, medium, and large
  let brushSize = {
    tiny: 3, // Specifically used for perimeter measurement
    small: 6,
    medium: 12,
    large: 18
  };

  // Build an optional object of styles to pass in to the estimator
  // These styles affect the DIV wrapping the estimator component
  // IMPORTANT: mapHeight and mapWidth passed in will override any
  // height and width values passed in via styles
  const styles = {
    // Add custom styles here
  };

  // BUILD CALLBACKS

  // Build function to update the estimator mode
  // For dev purposes only

  const updateMode = (mode) => {
    setMode(mode);
  }

  // Build function to update map center
  // Takes LatLng object as argument
  const updateMapCenter = (latLng) => {
    // console.log("Moved center of map:", latLng);
    // Update state
    setMapCenter(latLng);
  }

  // Build function to update map bounds
  // Takes an object as an argument with the following structure:
  // { sw: LatLng Object, ne: LatLng Object }
  const updateMapBounds = (data) => {
    // console.log("Here are the map bounds:", data);
    // Save data to state
    setMapBounds(data);
  }

  // Build function to return map area
  // Takes a numeric value as an argument
  const updateMapArea = (value) => {
    const area = `${value.toFixed(2)} square feet`;
    // console.log("Here is the total area of the map:", area);
    // Save data to state
    setMapArea(value);
  }

  // Build function to update canvas coverage percentage
  // Takes a numeric value as an argument
  // NOTE: Percentage here is in decimal format
  // (e.g. 10.5% has a value of .105)
  const updateCoveragePercentage = (value) => {
    const percentage = `${(value*100).toFixed(2)}%`;
    // console.log("Current coverage:", percentage);
    // Update state
    setCoveragePercentage(value);
  }

  // Build function to update canvas coverage square area
  // Takes a numeric value as an argument
  const updateCoverageSquareArea = (value) => {
    const area = `${value.toFixed(2)} square feet`;
    // console.log("Current area covered:", area);
    // Update state
    setCoverageSquareArea(value);
  }

  // Build function to update linear distance drawn on canvas
  // Takes a numeric value as an argument
  const updateDrawnLinearDistance = (value) => {
    const distance = `${value.toFixed(2)} linear feet`;
    // console.log("Current linear distance drawn:", distance);
    // Update state
    setDrawnLinearDistance(value);
  }

  // Build function to save the current HTML canvas drawing
  // Takes a base64 jpeg image as an argument
  const saveDrawing = async (image, original) => {
    // console.log("Saved image:", image);
    setImageData(image);
    setSaving(true);

    const meta = await uploadImage(image);
    const originalmeta = await uploadImage(original);

    const aiPayload = {
      dataset: {
        originalUrl: originalmeta.Location,
        measuredUrl: meta.Location,
        mapBounds,
        mapCenter,
        address,
        mode,
        estimate: (mode === 'area') ? coverageSquareArea : drawnLinearDistance,
      }
    }

    await uploadAIDataset(aiPayload);

    window.parent.postMessage({
      type: 'estimate',
      value: (mode === 'area') ? coverageSquareArea : drawnLinearDistance,
      mode, 
      image,
      meta
    }, "*");

    setSaving(false);

  }

  const getWhiteLabelJson = (data) => {
    return new Promise( async (res, rej) => {
      const url = 'https://efn1omnr6a.execute-api.us-east-1.amazonaws.com/test/estimates/whitelabel';

      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
  
      const meta = await response.json(); 
      res(meta);
    });
  }

  const uploadAIDataset = (data) => {
    return new Promise( async (res, rej) => {
      const url = 'https://api.measur.it/estimates/datasets';

      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
  
      const meta = await response.json(); 
      res(meta);
    });
  }

  const uploadImage = (image) => {
    return new Promise( async (res, rej) => {
      const url = 'https://api.measur.it/estimates/upload';

      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify({image}) // body data type must match "Content-Type" header
      });
  
      const meta = await response.json(); 
      res(meta);
    });
  }

  const [tourOpen, setTourOpen] = useState(true);
  const [drawTourOpen, setDrawTourOpen] = useState(false);
  
  const short = useMediaQuery(json2mq(shortQueries));

  const steps = [
    {
      selector: short ? 'body' : '.estimator',
      position: 'center',
      style: {top: short ? -45 : -30},
      content: ({ goTo, inDOM }) => {
        return (<MeasurItWelcome goTo={goTo} clientOptions={clientOptions} />)
      }
    },
      {
        selector: short ? 'body' : '.estimator',
        position: 'center',
        style: {top: short ? 150 : -30, 
             left: short ? 25 : 0
        },
        content: () => {
          return (<MeasurItPanZoomTour
            closeTour={setTourOpen} />)
        }
      },
    ];


    const drawSteps = [
      {
        selector: ".estimator",
        position: 'center',
        style: {top: -30,
          width: '95vw'
        },
        content: ({goTo}) => {
          return (<MeasurItColorEraseTour goTo={goTo} closeTour={setDrawTourOpen}  />)
        }
      },
      {
        selector: ".estimator",
        position: 'top',
        style: {top: -150,
          width: '95vw'
        },
        content: () => {
          return (<GoodBadEstimationsTour closeTour={setDrawTourOpen}  />)
        }
      },
    ]

    const containerStyle = {
      // display: 'inline-grid',
      // alignContent: 'center',
      // backgroundColor: '#ccc',
      // height: '100vh',
      // verticalAlign: 'middle'
      overflow: 'hidden'
    }

    // console.log(protocol, origin, host)
    
  return (
    <Strings.Provider value={strings}>
       <link rel="stylesheet" type="text/css" href={`https://${host}/measur.it/styles.css`} />
    <div className="measurit-container" style={containerStyle }>
      <MeasurItTitleBar 
        measurement={mode === 'area' ? coverageSquareArea : drawnLinearDistance} 
        mode={mode} 
        clientOptions={clientOptions}
        />
      { tourOpen ? 
          (<Tour 
          id="map-tour"
          steps={steps}
          isOpen={tourOpen}
          rounded={10}
          disableDotsNavigation={true}
          disableInteraction={true}
          showNavigation={false}
          showButtons={false}
          showNumber={false}
          clientOptions={clientOptions}

          onRequestClose={() => setTourOpen(false)}>
          </Tour>) : null }

          { drawTourOpen ? 
              (<Tour 
              id="drawing-tour"
              steps={drawSteps}
              isOpen={drawTourOpen}
              rounded={10}
              disableDotsNavigation={true}
              disableInteraction={true}
              showNavigation={false}
              showButtons={false}
              showNumber={false}
              onRequestClose={() => setDrawTourOpen(false)}>
              </Tour>) : null }

          {!loaded ? null : (
            <Estimator
            setDrawTourOpen={setDrawTourOpen}
            mode={mode}
            updateMode={updateMode} // Dev purposes only
            GOOGLE_API_KEY={GOOGLE_API_KEY}
            mapHeight={mapHeight}
            mapWidth={mapWidth}
            style={styles}
            mapCenter={mapCenter}
            updateMapCenter={updateMapCenter}
            startingZoomLevel={short ? startingZoomLevel - 1 : startingZoomLevel}
            updateMapBounds={updateMapBounds}
            updateMapArea={updateMapArea}
            color={color}
            brushSize={brushSize}
            updateCoveragePercentage={updateCoveragePercentage}
            updateCoverageSquareArea={updateCoverageSquareArea}
            updateDrawnLinearDistance={updateDrawnLinearDistance}
            saveDrawing={saveDrawing}
            clientOptions={clientOptions}
            className="estimator"
          />
          )}

        <Backdrop className={classes.backdrop} open={saving} onClick={()=> {}}>
          <CircularProgress color="inherit" />
        </Backdrop>
    </div>
    </Strings.Provider>
  );
}
